<template>
  <div>
    <v-container fluid fill-height>
      <c-ecosystem-nav :ecosystemId="ecosystemId"></c-ecosystem-nav>

      <v-row>
        <v-col>
          <v-container class="files-list">
            <v-row :style="{ maxHeight: titleRowHeight }">
              <v-col class="files-list__heading" cols="8" :sm="7">
                <c-page-heading
                  :heading="$t('files.list.manage')"
                  :subHeading="$t('files.list.files')"
                >
                </c-page-heading>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" :sm="7" class="text-left">
                <div class="sub-title font-weight-light" data-cy="uploadDownloadAndViewFiles">
                  {{ $t('files.list.uploadDownloadAndViewFiles') }}
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <m-table-view>
                  <template v-slot:toolbar>
                    <div class="table-toolbar">
                      <m-search-input
                        v-model="searchTerm"
                        :placeholder="$t('files.searchForFiles')"
                        class="table-search-bar"
                        @clear="searchTerm = null"
                      ></m-search-input>
                      <m-filter
                        :filters="filters"
                        @filterChange="filterChange($event)"
                        @reset="resetFilter"
                        :activatorLabel="$t('files.filter.buttons.activator')"
                        :applyLabel="$t('files.filter.buttons.apply')"
                        :resetLabel="$t('files.filter.buttons.reset')"
                      >
                        <template v-slot:default>
                          <div class="menu-content-container">
                            <div class="menu-content">
                              <div class="menu-content-search">
                                <span class="menu-title">{{ $t('files.filter.uploadedBy') }}</span>
                                <m-search-input
                                  class="search"
                                  :placeholder="$t('files.filter.search')"
                                  v-model="filterSearchTerm"
                                  @clear="filterSearchTerm = ''"
                                />
                                <div class="list-items-uploaded-by">
                                  <div class="no-results-found" v-if="!filteredUploadedBy.length">
                                    <p>{{ $t('files.filter.noResultsFound') }}</p>
                                  </div>
                                  <div v-else v-for="value in filteredUploadedBy" :key="value">
                                    <div class="checkbox">
                                      <div>
                                        <m-checkbox
                                          :value="value"
                                          v-model="filters.uploadedBy"
                                          :label="value"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="menu-content">
                              <span class="menu-title">{{ $t('files.filter.fileTypes') }}</span>
                              <div class="list-items-file-type">
                                <div v-for="value in fileTypes" :key="value">
                                  <div class="checkbox">
                                    <div>
                                      <m-checkbox
                                        :value="value"
                                        v-model="filters.fileTypes"
                                        :label="formatContentType(value)"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>
                      </m-filter>
                      <input
                        type="file"
                        ref="upload"
                        :accept="allowedFileTypes"
                        hidden
                        multiple
                        @change="onFileChange"
                      />
                      <m-button
                        :label="$t('files.add.uploadFile')"
                        @click="$refs.upload.click()"
                      ></m-button>
                    </div>
                  </template>
                  <template #default>
                    <m-table
                      v-model="selectedFiles"
                      class="files-list__table"
                      showSelect
                      :items="filteredFiles"
                      :headers="headers"
                      :page.sync="pageNumber"
                      :items-per-page="+perPage"
                      :disablePagination="false"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      droppable
                      @drop="onFileChange"
                      v-on:current-items="setDisplayedFiles"
                    >
                      <template #item.name="{ item }">
                        <a class="file-name" @click="preview(item)">{{ item.name }}</a>
                      </template>
                      <template #item.owner="{ item }">
                        {{
                          item.owner
                            ? `${item.owner.givenName} ${item.owner.surname}`
                            : $t('files.filter.nA')
                        }}
                      </template>
                      <template #item.created="{ item }">
                        {{ formatDate(item.created) }}
                      </template>

                      <template #item.contentType="{ item }">
                        {{ formatContentType(item.contentType) }}
                      </template>

                      <template #item.menu="{ item }">
                        <m-context-menu icon="more-vert-filled" :menuProps="contextMenuProps">
                          <m-context-menu-item
                            icon="edit-stroke"
                            :data-cy="`edit-file-${item.id}-btn`"
                            @click="openEditFileModal(item)"
                          >
                            {{ $t('files.list.edit') }}
                          </m-context-menu-item>
                          <m-context-menu-item
                            icon="download-stroke"
                            :data-cy="`download-file-${item.id}-btn`"
                            @click="downloadFile(item)"
                            :disabled="
                              filesDownloading.includes(item) ||
                              item.contentType?.includes('video/')
                            "
                          >
                            {{
                              filesDownloading.includes(item)
                                ? $t('files.list.downloading')
                                : $t('files.list.download')
                            }}
                          </m-context-menu-item>
                          <m-context-menu-item
                            icon="delete-stroke"
                            error
                            :data-cy="`remove-file-${item.id}-btn`"
                            @click="confirmDelete([item])"
                          >
                            {{ $t('files.list.delete') }}
                          </m-context-menu-item>
                        </m-context-menu>
                      </template>
                    </m-table>
                  </template>

                  <template #footer>
                    <v-divider class="mb-5"></v-divider>

                    <m-pagination
                      :total="filteredFiles.length"
                      :pageNumber="pageNumber"
                      :perPage="perPage"
                      v-on:prev="pageNumber--"
                      v-on:next="pageNumber++"
                      v-on:perPage="changeSize"
                    />
                  </template>
                </m-table-view>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>

    <m-bulk-select-panel
      v-show="selectedFiles.length > 0"
      :quantity="selectedFiles.length"
      name="file"
      v-on:unselect-all="unselectAll"
      v-on:bulk-action="confirmDelete(selectedFiles)"
    ></m-bulk-select-panel>

    <c-delete-modal
      :deleteModal="deleteModal"
      v-on:close="
        deleteModal.show = false
        deleteModal.items = null
      "
      v-on:delete="deleteFiles"
    />

    <c-file-preview-modal
      v-model="previewModal.show"
      :title="previewModal.title"
      :subtitle="previewModal.subtitle"
      :file="previewModal.file"
      :message="previewModal.message"
      :loading="fileLoading"
      @delete="showDelete(selectedFile)"
      @download="downloadFile(selectedFile)"
      @edit="openEditFileModal(selectedFile)"
      @close="closePreview()"
    />

    <c-edit-file-modal
      v-model="editModal.show"
      :file="selectedFile"
      @update="updateFile($event)"
      @delete="confirmDelete([selectedFile])"
      @close="editModal.show = false"
      @preview="preview(selectedFile)"
    />

    <m-action-status-panel
      id="upload-panel"
      v-model="statusPanel.show"
      :menuProps="statusPanel.menuProps"
      :title="statusPanelTitle"
      :showClose="itemStatuses.pending === 0"
      bottom
      right
    >
      <m-action-status-panel-item
        v-for="(item, index) in uploadQueue"
        :key="item.file.name + index"
        :status="item.status"
        :icon="item.icon"
        :iconColor="item.iconColor"
        :title="item.file.name"
        :subtitle="item.message || item.fileSize"
      />
    </m-action-status-panel>

    <m-snackbar v-model="showSnackbar">
      <img :src="infoIcon" width="24" />
      {{ $t('files.add.dragAndDropFilesToUpload') }}
    </m-snackbar>
  </div>
</template>
<script>
import { DateTime } from 'luxon'
import { filter } from '../../utilities/search'
import {
  MActionStatusPanel,
  MActionStatusPanelItem,
  MBulkSelectPanel,
  MButton,
  MCheckbox,
  MContextMenu,
  MContextMenuItem,
  MFilter,
  MPagination,
  MSearchInput,
  MSnackbar,
  MTable,
  MTableView,
} from 'gatherings-storybook'
import InfoIcon from 'gatherings-storybook/src/icons/info-filled.svg'
import { countBy } from 'lodash'
import { formatFileSize, isValidMimeType } from '@/utilities/files'

const MAX_UPLOAD_SIZE_IN_MB = parseInt(process.env.VUE_APP_MAX_UPLOAD_SIZE_IN_MB) || 50

export default {
  props: ['ecosystemId'],
  components: {
    MActionStatusPanel,
    MActionStatusPanelItem,
    MBulkSelectPanel,
    MButton,
    MCheckbox,
    MContextMenu,
    MContextMenuItem,
    MFilter,
    MPagination,
    MSearchInput,
    MSnackbar,
    MTable,
    MTableView,
  },
  data() {
    return {
      ecosystem: {},
      files: [],
      windowWidth: window.innerWidth,
      loading: false,
      selectedFile: undefined,
      searchTerm: null,
      selectedFiles: [],
      displayedFiles: [],
      headers: [
        {
          value: 'name',
          text: this.$t('files.list.table.filename'),
        },
        {
          value: 'owner',
          text: this.$t('files.list.table.uploadedBy'),
          sort: (a, b) => {
            const aName = `${a?.givenName} ${a?.surname}`
            const bName = `${b?.givenName} ${b?.surname}`
            if (aName === bName) return 0
            return aName > bName ? 1 : -1
          },
        },
        {
          value: 'contentType',
          text: this.$t('files.list.table.contentType'),
        },
        {
          value: 'created',
          text: this.$t('files.list.table.created'),
        },
        {
          value: 'menu',
          sortable: false,
          width: '56px',
          align: 'center',
        },
      ],
      contextMenuProps: {
        left: true,
        'nudge-left': '12px',
      },
      sortBy: 'created',
      sortDesc: true,
      pageNumber: 1,
      perPage: 10,
      allowedFileTypes: ['application/pdf', 'image/*', 'video/*'],
      uploadQueue: [],
      statusPanel: {
        show: false,
        menuProps: {
          attach: '#routerViewport',
          right: true,
          bottom: true,
        },
      },
      showSnackbar: false,
      filterSearchTerm: '',
      filters: {
        fileTypes: ['PDF', 'Video'],
        uploadedBy: [],
      },
      appliedFilters: {
        fileTypes: ['PDF', 'Video'],
        uploadedBy: [],
      },
      people: [],
      fileTypes: ['PDF', 'Video', 'Image'],
      previewModal: {
        show: false,
        title: null,
        subtitle: null,
        width: '100%',
        maxWidth: '90%',
        height: '90vh',
        file: null,
        message: null,
      },
      editModal: {
        show: false,
      },
      deleteModal: {
        show: false,
        title: this.$t('files.list.deleteThisFile'),
        message: this.$t('files.list.areYouSureYouWantToDeleteThisFile'),
        subMessage: this.$t('files.list.thisCannotBeUndone'),
        width: this.$vuetify.breakpoint.smAndUp ? 'auto' : undefined,
        items: [],
      },
      edit: {
        fileName: null,
      },
    }
  },
  watch: {
    uploadQueue(files) {
      const filesToUpload = files.filter(file => file.status === 'pending')

      if (filesToUpload.length > 0) {
        this.uploadFiles(filesToUpload.map(item => item.file))
      }
    },
    'statusPanel.show'(value) {
      if (!value) {
        this.uploadQueue = []
      }
    },
    'edit.fileName': function (newValue) {
      if (newValue !== this.selectedFile.name) {
        this.editModal.submitDisabled = false
      }
    },
  },
  computed: {
    titleRowHeight() {
      return this.$vuetify.breakpoint.width <= 540 ? '200px' : '120px'
    },
    numberOfPages() {
      if (this.filteredFiles.length < this.perPage) {
        return 1
      }
      let result = this.filteredFiles.length / this.perPage
      let remainder = this.filteredFiles.length % this.perPage

      return remainder === 0 ? result : (this.filteredFiles.length - remainder) / this.perPage + 1
    },
    filteredFiles() {
      const filters = {
        search: item => this.searchTable(item) ?? item,
        uploadedBy: item => {
          const { uploadedBy } = this.appliedFilters
          return (
            !uploadedBy.length ||
            uploadedBy.includes(`${item.owner?.givenName} ${item.owner?.surname}`) ||
            (!item.owner && uploadedBy.includes(this.$t('files.filter.nA')))
          )
        },
        fileType: item => {
          let type = this.formatContentType(item.contentType)
          const { fileTypes } = this.appliedFilters
          return !fileTypes.length || fileTypes.includes(type)
        },
      }
      const tableFilter = filter(this.files, filters)
      return tableFilter
    },
    filteredUploadedBy() {
      let people = this.people
      people = people.filter(person => {
        if (person.toLowerCase().indexOf(this.filterSearchTerm.toLocaleLowerCase()) > -1) {
          return person
        }
      })
      return people
    },
    statusPanelTitle() {
      const { pending, error, success } = this.itemStatuses

      if (pending > 0) {
        return this.$t('files.add.filesUploading', { count: pending })
      }

      if (error > 0) {
        return this.$t('files.add.filesNotUploaded', { count: error })
      }

      if (success > 0) {
        return this.$t('files.add.filesUploaded', { count: success })
      }

      return null
    },
    itemStatuses() {
      return {
        pending: 0,
        success: 0,
        error: 0,
        ...countBy(this.uploadQueue, 'status'),
      }
    },
    infoIcon() {
      return InfoIcon
    },
    fileLoading() {
      return this.$store.getters['files/fileLoading']
    },
    filesDownloading() {
      return this.$store.getters['files/filesDownloading']
    },
    formButtons() {
      return {
        Submit: {
          show: false,
        },
      }
    },
    selectedDisplayedFiles() {
      return this.displayedFiles.filter(df => this.selectedFiles.find(sf => sf.id === df.id))
    },
    allDisplayedSelected() {
      return this.selectedDisplayedFiles.length === this.displayedFiles.length
    },
  },
  async mounted() {
    window.addEventListener('resize', () => (this.windowWidth = window.innerWidth))
    document.addEventListener('dragenter', () => (this.showSnackbar = true))

    // Get files
    this.loading = true
    await this.fetchFiles()
    this.loading = false
  },
  methods: {
    async fetchFiles() {
      await this.$store.dispatch('files/fetchForEcosystem', this.ecosystemId)
      this.files = this.$store.getters['files/getForEcosystem'](this.ecosystemId)
      this.people = this.$store.getters['files/getForEcosystemUploadedBy'](this.ecosystemId)
    },
    async updateFile(value) {
      await this.$store
        .dispatch('files/update', {
          ecosystemId: this.ecosystemId,
          file: this.selectedFile,
          payload: value,
        })
        .then(this.fetchFiles)
    },
    downloadFile(file) {
      this.$store.dispatch('files/downloadFile', { file, ecosystemId: this.ecosystemId })
    },
    confirmDelete(files) {
      this.deleteModal.title =
        files.length > 1
          ? this.$t('files.list.deleteNFiles', { n: files.length })
          : this.$t('files.list.deleteThisFile')

      this.deleteModal.message =
        files.length > 1
          ? this.$t('files.list.areYouSureYouWantToDeleteTheseFiles')
          : this.$t('files.list.areYouSureYouWantToDeleteThisFile')

      this.deleteModal.items = files
      this.deleteModal.show = true
    },
    async deleteFiles(files) {
      this.$store.dispatch('ui/toggleLoading', { isLoading: true })
      try {
        // Following code is to allow for easy demonstration to non technical team members how this would look if the delete failed
        // await new Promise(resolve => setTimeout(resolve, 4000))
        // throw new Error('Sorry, something has gone wrong and your file was not deleted')

        await this.$store.dispatch('files/remove', {
          ecosystemId: this.ecosystemId,
          files: files,
        })
      } catch (error) {
        let message = (error.response && error.response.data) || error.message || error.toString()
        this.deleteModal.show = false
        this.$store.dispatch('ui/toggleLoading', { isLoading: false })
        this.$store.dispatch('ui/launchSnackbar', {
          color: 'red',
          message,
          subMessage: this.$t('files.list.fileDeletionNotCompleted'),
          buttonColor: 'white',
          icon: 'mdi-alert-octagon',
        })
        return
      }

      this.$store.dispatch('ui/toggleLoading', { isLoading: false })
      this.files = this.$store.getters['files/getForEcosystem'](this.ecosystemId)

      this.$store.dispatch('ui/launchSnackbar', {
        color: 'green',
        message: this.$t('files.list.fileDeletionSuccessful', {
          n: this.deleteModal.items.length,
          item: this.deleteModal.items.length > 1 ? 'files' : 'file',
        }),
        buttonColor: 'white',
        icon: 'mdi-checkbox-marked-circle',
      })

      this.deleteModal.items = null
      this.deleteModal.show = false
      this.selectedFiles = this.selectedFiles.filter(sf => !files.find(file => file.id === sf.id))
    },
    openEditFileModal(file) {
      this.editModal.show = true
      this.selectedFile = file
    },
    searchTable(item) {
      if (!this.searchTerm) {
        return undefined
      } else {
        return (
          `${item.owner?.givenName} ${item.owner?.surname}`
            .toLocaleLowerCase()
            .includes(this.searchTerm.toLocaleLowerCase()) ||
          item.name.toLocaleLowerCase().includes(this.searchTerm.toLocaleLowerCase())
        )
      }
    },
    formatDate(date) {
      if (!date) return null
      return DateTime.fromISO(date).toFormat('EEE DD, H:mm (ZZZZ)')
    },
    formatContentType(type) {
      if (!type) return this.$t('files.filter.nA')

      if (type === 'application/pdf') {
        return this.$t('files.list.contentType.pdf')
      }

      if (type.includes('image/')) {
        return this.$t('files.list.contentType.image')
      }

      if (type.includes('video/')) {
        return this.$t('files.list.contentType.video')
      }

      return type
    },
    changeSize(size) {
      this.perPage = size

      if (size >= this.filteredFiles.length) {
        this.pageNumber = 1
      }
      if (this.pageNumber > this.numberOfPages) {
        this.pageNumber = this.numberOfPages
      }
    },
    /**
     * Returns a relevant file icon for the file
     * @param {File} file the file
     */
    getFileIcon(file) {
      if (file.type === 'application/pdf') {
        return { icon: 'file-pdf-solid', iconColor: '#FF5630' }
      }

      if (file.type.includes('image/')) {
        return { icon: 'file-image-solid', iconColor: '#FFAB00' }
      }

      if (file.type.includes('video/')) {
        return { icon: 'file-video-solid', iconColor: '#FF5630' }
      }

      return { icon: 'file-solid', iconColor: '#5E6C84' }
    },
    /**
     * Validates a file returning an error message string if necessary
     * @param {File} file The file
     * @return {string} The error message or null if validation has passed
     */
    validateFile(file) {
      if (!isValidMimeType(file.type, this.allowedFileTypes)) {
        return this.$t('files.add.fileTypeValidationMsg')
      }
      if (file.size > MAX_UPLOAD_SIZE_IN_MB * 1024 * 1024) {
        return this.$t('files.add.fileSizeValidationMsg')
      }

      return null
    },
    /**
     * Adds a list of files to the upload queue
     * @param {FileList} files The list of files
     */
    addFilesToQueue(files) {
      for (let file of files) {
        // Validate file
        const message = this.validateFile(file)
        const icon = this.getFileIcon(file)

        // Add to the queue to show to the user
        this.uploadQueue.push({
          file,
          message,
          fileSize: formatFileSize(file.size),
          status: message ? 'error' : 'pending',
          ...icon,
        })
      }
    },
    /**
     * Triggered when a user drops files on the table or when they upload a file to the file input
     * @param {Event} event The event containing the files
     */
    onFileChange(event) {
      // Pull files from input or from dropped event
      const { files } = event.dataTransfer || event.target

      this.addFilesToQueue(files)

      // Show the status panel
      this.statusPanel.show = true
      this.showSnackbar = false

      // Reset file input
      if (!event.dataTransfer) {
        this.$refs.upload.value = null
      }
    },
    /**
     * Uploads a list of files
     * @param {Array<File>} files Array of file objects to upload
     */
    async uploadFiles(files) {
      try {
        const response = await this.$store.dispatch('files/upload', {
          ecosystemId: this.ecosystemId,
          files,
        })

        const { message, data } = response.data

        if (message === 'Succeeded') {
          this.updateFilesInQueue(data)
          // Update files list
          await this.fetchFiles()
        } else {
          throw new Error('Request completed but message returned unsuccessful')
        }
      } catch (e) {
        // Mark files as unsuccessful
        this.updatePendingFilesError()
      }
    },
    /**
     * Updates the queued files with the reponse statuses
     * @param {Array} files Array of file statuses
     */
    updateFilesInQueue(files) {
      const pendingFiles = this.uploadQueue.filter(file => file.status === 'pending')

      files.forEach(({ name, succeeded, errorMessage }) => {
        const index = pendingFiles.findIndex(item => item.file.name === name)

        if (index > -1) {
          const item = pendingFiles[index]

          if (succeeded) {
            item.status = 'success'
          } else {
            item.status = 'error'
            item.message = errorMessage
          }
        }
      })
    },
    /**
     * Updates the queued files with a error message
     */
    updatePendingFilesError() {
      const pendingFiles = this.uploadQueue.filter(file => file.status === 'pending')

      pendingFiles.forEach(item => {
        item.status = 'error'
        item.message = this.$t('files.add.uploadFailed')
      })
    },
    filterChange(filters) {
      this.filterSearchTerm = ''
      Object.assign(this.appliedFilters, filters)
      Object.assign(this.filters, filters)
    },
    resetFilter() {
      this.filterSearchTerm = ''
      Object.assign(this.appliedFilters, { fileTypes: [], uploadedBy: [] })
      Object.assign(this.filters, { fileTypes: [], uploadedBy: [] })
    },
    /**
     * Previews a file
     */
    async preview(file) {
      // Set the selected file for delete/edit/download
      this.selectedFile = file

      this.previewModal.message = null
      this.previewModal.show = true
      this.previewModal.title = file.owner
        ? `${file.owner.givenName} ${file.owner.surname}`
        : this.$t('files.filter.nA')
      this.previewModal.subtitle = `${this.formatDate(file.created)} - ${file.name}`

      // There is no content type, so we don't know the format to preview
      if (!file.contentType) {
        // Don't attempt to download the file just display the popup with a message
        this.previewModal.message = this.$t('files.list.cantPreviewThisFile')
        return
      }

      if (file.contentType?.includes('video/')) {
        this.previewModal.file = {
          video: file.assetUrl,
          type: file.contentType,
        }
      } else {
        const { data } = await this.$store.dispatch(`files/fetchFile`, {
          file,
          ecosystemId: this.ecosystemId,
        })
        // Create a file object for the preview to render
        this.previewModal.file = new File([data], file.name, { type: data.type })
      }
    },
    /**
     * closes the file preview modal
     */
    closePreview() {
      this.previewModal.show = false
      this.previewModal.file = null
    },
    closeEditModal() {
      this.editModal.show = false
      this.editModal.submitDisabled = true
    },
    unselectAll() {
      this.selectedFiles = []
    },
    setDisplayedFiles(files) {
      this.displayedFiles = files
    },
    /**
     * Shows the delete modal
     */
    showDelete(file) {
      this.selectedFile = file
      this.deleteModal.items.push(file)
      this.deleteModal.show = true
    },
  },
}
</script>

<style scoped lang="scss">
.sub-title {
  font-size: 1.3rem;
}

.file-name {
  font-weight: 700;
  line-height: 20px;
  color: $black;
}
.files-list {
  // TODO: Update vuetify breakpoints variable to use these breakpoint settings
  @media (min-width: 960px) {
    max-width: 840px;
  }

  @media (min-width: 1264px) {
    max-width: 1120px;
  }

  @media (min-width: 1904px) {
    max-width: 1240px;
  }

  &__table,
  &__heading,
  .button {
    :deep(*) {
      font-family: 'Mulish' !important;
    }
  }
}
.table-toolbar {
  width: 100%;
  display: flex;
  align-items: flex-end;
  gap: 12px;
  :deep(*) {
    font-family: 'Mulish' !important;
  }
}
.table-search-bar {
  flex: content;
}
.menu-content-container {
  display: flex;
  flex-wrap: wrap;
  :deep(*) {
    font-family: 'Mulish' !important;
  }
}
.menu-title {
  font-weight: 700;
}
.menu-content {
  flex: content;
  margin-right: 2rem;
}
.menu-content-search {
  width: 298px;
}
.search {
  margin-bottom: 0.5rem;
}
.no-results-found {
  display: flex;
  justify-content: center;
}
.checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
  .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
    :deep(.v-input__slot) {
      margin-bottom: 0;
      .v-label {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5rem;
        color: $black;
      }
    }
  }
}
.list-items-uploaded-by {
  max-height: 258px;
  padding: 0.75rem 0.875rem;
  border: 1px solid $grey-border;
  border-radius: 0.5rem;
  overflow: hidden;
  overflow-y: scroll;
}
.list-items-file-type {
  margin-top: 0.5rem;
}

.edit-modal-content {
  margin-top: 12px;
  :deep(*) {
    font-family: 'Mulish' !important;
    font-size: 1rem;
  }
  :deep(.value) {
    font-size: 1.1429rem;
  }
}
</style>

<style lang="scss">
.snackbar *,
.action-status-panel * {
  font-family: 'Mulish' !important;
}

.context-menu.list {
  border: none;

  .list-item__content {
    font-family: 'Mulish';
  }
}
.v-application {
  .v-menu__content {
    .menu > .menu__footer > button > span {
      font-family: 'Mulish' !important;
    }
  }
  .v-dialog__content {
    .v-dialog > .modal {
      * {
        font-family: 'Mulish' !important;
      }

      .modal__top-container {
        .modal__header {
          font-weight: 400;
          font-size: 1.4286rem;
          line-height: 32px;
        }
      }
    }
  }
}

// Retained for future use - TODO fix indeterminate icon color when selecting all
// #select-all-files-checkbox .v-icon {
//   color: $primary !important;
// }
</style>
